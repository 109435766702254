let InfoForUsersGsmBulkUpload = [
    {
        label: "Model:",
        value: "Model Name are assigned by Maker of the Phone devices. For an e.g. Apple IPHONE has various version of their phone such as IPHONE 4, 5s etc.. These Model Name are added to Vcare Inventory system which generates Unique Model ID for each of them which can be used here for identification & tracking purpose.",
    },
    {
        label: "Box:",
        value: "Its Unique number given to Bundle of Sim/ESN/Device by Warehouse person for their identifier & tracking purpose",
    },
];

export { InfoForUsersGsmBulkUpload };
