import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
const BASE_URL = process.env.REACT_APP_BASE_URL
export const AddButtonPermission = () => {

    const validationSchema = Yup.object().shape({
        role:Yup.string().required("Please Select Role")
    })
const formik= useFormik ({
    validationSchema :validationSchema,
    initialValues:{
        role:""
    },
    onSubmit:async ()=>{
        const data = {
            // serviceProvider: parseLoginRes?.company,
            // permissions: permissions,
            // role: formik.values.role,
            // description: formik.values.description,
            // isSperPanelRole: false,
        };
        try {
            const response=  await Axios.post(`${BASE_URL}/api/web/permission`,data)
            if(response?.status===200 || response?.status===201){
                toast.success("Added Successfully")
               }
        } catch (error) {
            toast.error(error?.response?.data?.msg)
            console.log("error is",error)
        }
     
    }
})

const getAllRole = async()=>{

   const response =  await Axios.get(`${BASE_URL}/api/web/role/all`)
    console.log("all role is",response?.data)
}
const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
};
  return (
    <>
     <div className="card">
        <ToastContainer/>
                <h3 className="mt-1 ">Add Button Permission</h3>
            </div>
            <div className="card">
               
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid grid justify-content-around">
                            <div className="col-12 md:col-4" style={{ width: "45%" }}>
                                <label className="Label__Text"> Select Role</label>
                                <Dropdown  />
                                {getFormErrorMessage("role")}
                            </div>
                        </div>
                        <div>
                            <Button style={{ marginTop: "34px", marginLeft: "50%", transform: "translate(-50%)" }} label="Submit" type="submit"  />
                        </div>

                    </form>
                </div>
                </div>
    </>
  )
}
